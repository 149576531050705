import React, { useState, useEffect } from "react";

const SelectableTags = ({ relevantTags, alreadySelectedTags = [], onSelectionChange, language }) => {
  const [selectedTags, setSelectedTags] = useState([]);

  // WHY DO I NEED THIS TO MAKE selectedTags HAVE INIT VAL?
  useEffect(() => {
    setSelectedTags(alreadySelectedTags)
  });

  const toggleTagSelection = (selectedTag) => {
    let updatedSelection;
    if (selectedTags.find((t) => t.tag.name === selectedTag.tag.name)) {
      // Unselecting the tag
      updatedSelection = selectedTags.filter((t) => t.tag.name !== selectedTag.tag.name);
    } else {
      // Selecting the tag
      updatedSelection = [...selectedTags, selectedTag];
    }
    setSelectedTags(updatedSelection);
    onSelectionChange(updatedSelection); // Call the callback with the updated selection
  };

  // It should be handled in backend.
  const isManualTag = (name) => {
    if (name === "like" || name === "todo" || name === "finished") {
      return true;
    }
    return false;
  }

  const tagName = (name) => {
    const mapping = {
      'en': {
        'like': 'favorite',
        'todo': 'todo',
        'finished': 'checked out',

        'book': '📖',
        'movie': '🎥',
        'game': '🎮',
        'youtubechannel': 'youtube'
      },
      'ru': {
        'like': 'любимое',
        'todo': 'планы',
        'finished': 'ознакомлен(а)',

        'book': '📖',
        'movie': '🎥',
        'game': '🎮',
        'youtubechannel': 'youtube'
      }
    }
    const val = mapping[language][name];
    if (val === undefined) {
      return name;
    } else {
      return val;
    }
  }

  const tagStyle = (tag) => {
    const { name, creatorUser } = tag;

    if (creatorUser) {
      return "bg-gray-200 text-gray-700";
    }

    // Global tags below.

    if (isManualTag(name)) {
      if (name === "like") {
        return "bg-red-500/80 text-gray-700";
      } else if (name === "todo") {
        return "bg-blue-200/50 text-gray-700";
      } else {
        return "bg-yellow-200/50 text-gray-700";
      }
    }

    // category tag: book, movie, game etc.
    return "bg-green-200/30 text-gray-700";
  }

  return (
    <div className="flex overflow-x-auto space-x-2">
      {relevantTags.map((tagObject, index) => {
        const { name, creatorUser } = tagObject.tag;
        const { count } = tagObject;

        const tagStyleUnselected = tagStyle(tagObject.tag);

        return (
          <div
            key={index}
            className={`cursor-pointer px-4 py-2 rounded whitespace-nowrap ${
              selectedTags.find((t) => t.tag.name === name)
                ? "bg-blue-600 text-white"
                : tagStyleUnselected
            }`}
            onClick={() => toggleTagSelection(tagObject)}
          >
            {"#"+tagName(name)} <span className="text-sm text-gray-500">({count})</span>
          </div>
        );
      })}
    </div>
  );
};

export default SelectableTags;