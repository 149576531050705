import React, { useState, useEffect, useContext } from 'react';
import axiosInstance from './../axiosInstance.js';
import {localizeKey, localizeArt} from './../localize.js';
import MyUserContext from './../myUserContext.js';
import CopyTextButton from './CopyTextButton';
import AvatarUpload from './AvatarUpload';
import ExpandableImage from './ExpandableImage';
import ToggleSwitch from './ToggleSwitch';
import Collapsible from './Collapsible';

const Settings = ({ onSettingsChange }) => {
  const myUser = useContext(MyUserContext);

  const [selectedLanguage, setSelectedLanguage] = useState('en'); // Default language
  const [selectedUserName, setSelectedUserName] = useState(myUser.myUserSettings.userName);
  const [isLoading, setIsLoading] = useState(true); // State to manage loading state
  const [error, setError] = useState(null); // State to manage errors
  const [tgToken, setTgToken] = useState(null);
  const [userNameInput, setUsernameInput] = useState(myUser.myUserSettings.userName);
  const [selectedIsPrivateAccount, setSelectedIsPrivateAccount] = useState(myUser.myUserSettings.privateAccount);


  const [userSettings, setUserSettings] = useState(null);

  // Add an interceptor to add authorization header to requests
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('jwtToken'); // Get token from local storage
      if (token) {
        config.headers.Authorization = `Bearer ${token}`; // Add authorization header
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    // Fetch initial language setting when the component mounts
    axiosInstance
      .get('/user/settings')
      .then((response) => {
        const { lang } = response.data;
        setSelectedLanguage(lang); // TODO: do not process it independently
        setUserSettings(response.data);
        setIsLoading(false); // Update loading state after successful response
      })
      .catch((error) => {
        setError(error.message); // Set error state with error message
        setIsLoading(false); // Update loading state after error
      });
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts


  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setIsLoading(true); // Set loading state to true

    axiosInstance
    .post('/user/settings', { lang: newLanguage })
    .then((response) => {
      const { lang } = response.data;
      setSelectedLanguage(lang); // Update language state with the response
      onSettingsChange(lang, response.data.userName, response.data.avatarUrl);
      setError(null); // Clear any previous errors
    })
    .catch((error) => {
      setError(error.message); // Set error state with error message
    })
    .finally(() => {
      setIsLoading(false); // Set loading state to false regardless of success or failure
    });
  };

  const generateTgToken = () => {
    //setIsLoading(true);
    axiosInstance
    .post('/user/generateTelegramToken', {})
    .then((response) => {
      setTgToken(response.data.token);
      setTimeout(()=>{
         setTgToken(null);
      }, 20000);
    })
    .catch((error) => {
      setError(error.message); // Set error state with error message
    })
    .finally(() => {
      //setIsLoading(false); // Set loading state to false regardless of success or failure
    });
  }

  const handleUsernameInputChange = (event) => {
    setUsernameInput(event.target.value);
  };
  const handleUsernameChange = () => {
    axiosInstance
    .post('/user/settings', { userName: userNameInput })
    .then((response) => {
      setSelectedUserName(response.data.userName);
      onSettingsChange(response.data.lang, response.data.userName, response.data.avatarUrl);
      setError(null); // Clear any previous errors
    })
    .catch((error) => {
      setError(error.message); // Set error state with error message
    })
    .finally(() => {
      setIsLoading(false); // Set loading state to false regardless of success or failure
    });
  }

  const handleAccountIsPrivate = (isPrivate) => {
    axiosInstance
    .post('/user/settings', { privateAccount: isPrivate })
    .then((response) => {
      setSelectedIsPrivateAccount(isPrivate);
      setError(null);
    })
    .catch((error) => {
      setError(error.message);
    })
    .finally(() => {
      setIsLoading(false); // TODO: get rid of it
    })
  }

  const settingsAvatarUrlChange = (url) => {
    onSettingsChange(selectedLanguage, selectedUserName, url);
  }

  const language = myUser ? myUser.myUserSettings.lang : 'ru';

  if (isLoading) {
    return <div></div>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div class="container mx-auto px-8">    
      <br/>
      {isLoading && <p></p>}
      {error && <p>Error: {error}</p>}

      <div class="flex justify-center items-center">
        <input
          class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5"
          type="text"
          value={userNameInput}
          onChange={handleUsernameInputChange}
        />
        &nbsp;
        <button onClick={handleUsernameChange}>{localizeKey('setUserName', selectedLanguage)}</button>
      </div>

      <br/>
      <div class="flex justify-center items-center">
        <AvatarUpload initUrl={userSettings.avatarUrl} onAvatarUrlChange={settingsAvatarUrlChange} />
      </div>

      <br/>

      <div class="flex justify-center">
        <label>
          <input
            type="radio"
            value="en"
            checked={selectedLanguage === 'en'}
            onChange={handleLanguageChange}
            disabled={isLoading}
          />
          English
        </label>
        &nbsp;
        <label>
          <input
            type="radio"
            value="ru"
            checked={selectedLanguage === 'ru'}
            onChange={handleLanguageChange}
            disabled={isLoading}
          />
          Русский
        </label>
      </div>

      <div class="mt-7 flex justify-center items-center">
        <ToggleSwitch isOn={userSettings.privateAccount} onToggle={handleAccountIsPrivate} /> &nbsp; {(selectedLanguage === 'ru') ? "Приватный аккаунт" : "Private account"}
      </div>
      <div class="flex justify-center text-center leading-none">
      {
        selectedLanguage === 'ru' ?
        "Только одобренные подписчики будут видеть вашу страницу" :
        "Only approved subscribers will see your page"
      }
      </div>
      
      <div class="mt-7 flex justify-center">
        {localizeKey('click_cap', selectedLanguage)} <img style={{cursor: 'pointer', width: '25px', 'vertical-align': 'middle'}} onClick={generateTgToken} src="telegram_icon.png"/>{localizeKey('toGetNotifications', selectedLanguage)}
      </div>

      { 
        tgToken && 
        <>
          <div class="flex justify-center">
            <CopyTextButton
              textToCopy={
                tgToken
              }
              overrideButtonText={
                localizeKey('copySecretToken_cap', selectedLanguage)
              }
            />
          </div>
          <div class="flex justify-center">
            {localizeKey('sendIt_cap', selectedLanguage)}&nbsp;<a class="text-blue-600" href='https://t.me/wowContentLolBot' target="_blank">{localizeKey('here_direction', selectedLanguage)}</a>
          </div>
        </>  
      }

      <div class="flex justify-center mb-4 mt-7">
        <div>
          <Collapsible doOverrideNativeText={true} buttonText={language === "ru" ? "Как работает приложение" : "How app works"} collapsed={true}>
            <iframe src="https://drive.google.com/file/d/1_CI8FFFGJDVSBvTftI3_AYZw_xSnq6As/preview" width="640" height="480" allow="autoplay"></iframe>
          </Collapsible>
        </div>
      </div>

      {
      selectedLanguage === "en" ? 
      <>
        <div class="mt-60 flex justify-center text-center">
          <div>Hint: for a much better experience on mobile phone, add the website to home screen in your browser.</div>
        </div>        
      </>
        :
      <>
        <div class="mt-60 flex justify-center text-center">
          <div>Рекомендация: для большего удобства на мобильных телефонах добавьте веб-сайт на домашний экран из браузера.</div>        
        </div>
        <div class="flex justify-center items-center text-center">
          Инструкция для Android: &nbsp;          
          <ExpandableImage small={true} src={"https://wowcontent.ams3.cdn.digitaloceanspaces.com/add_to_home_screen_android.jpg"} />
        </div>
        <div class="flex justify-center items-center text-center">
          Инструкция для iOS: &nbsp;          
          <ExpandableImage small={true} src={"https://wowcontent.ams3.cdn.digitaloceanspaces.com/add_to_home_screen_ios.jpg"} />
        </div>
      </> 
      }
    </div>
  );
};

export default Settings;