import React, { useEffect, useRef } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

const ScrollManager = ({ children }) => {
  const location = useLocation();
  const navigationType = useNavigationType();
  const scrollPositions = useRef({});

  useEffect(() => {
    // Save the current scroll position when navigating away
    const handleBeforeUnload = () => {
      scrollPositions.current[location.pathname] = window.scrollY;
    };

    // Restore scroll position if navigating back
    if (navigationType === "POP") {
      const savedPosition = scrollPositions.current[location.pathname];
      if (savedPosition !== undefined) {
        window.scrollTo(0, savedPosition);
      }
    } else {
      // For new navigation, scroll to top
      window.scrollTo(0, 0);
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // Save scroll position when component unmounts
      handleBeforeUnload();
    };
  }, [location, navigationType]);

  return <>{children}</>;
};

export default ScrollManager;